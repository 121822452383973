



















































































































































import Vue from "vue";
import Component from "vue-class-component";
import { Locations, Auth } from "@/store/modules";
import firebase from "firebase";
import { omit } from "ramda";
import Terms from "@/components/Terms.vue"

@Component({
  components: {
    Terms
  }
})
export default class Home extends Vue {
  email = "";
  password = "";
  confirmPassword = "";
  terms = false;
  name = "";
  googleSignInParams = {
    client_id:
      "781437415526-5t6vs0av34o6eu8j62dsg3g6s0viogco.apps.googleusercontent.com",
  };
  isLoading = false;
  isLoadingRegister = false;
  isLoadingLogin = false;
  loginError = "";
  loginEmailError = "";
  registerError = "";
  view = "main";
  termsDialog = false;
  requiredRule = (v: any) => !!v || "This field is required";
  emailRule = (v: string) =>
    // eslint-disable-next-line no-control-regex
    /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0B\x0C\x0E-\x1F\x21\x23-\x5B\x5D-\x7F]|\\[\x01-\x09\x0B\x0C\x0E-\x7F])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0B\x0C\x0E-\x1F\x21-\x5A\x53-\x7F]|\\[\x01-\x09\x0B\x0C\x0E-\x7F])+)\])$/.test(
      v
    ) || "Please enter correct e-mail address"
  passwordRule = (v: string) => (v && v.length >= 8) || "Password must be at least 8 characters long";
  repeatRule = (repeat: string) => (v: string) => v === repeat || "Passwords must match";

  get loading() {
    return this.isLoading;
  }

  get error() {
    return Auth.error;
  }

  get isRedirect() {
    return this.$route.query.redirect === "true";
  }

  async doLogin() {
    this.isLoading = true;
    try {
      this.loginError = "";
      this.$router.push({
        path: this.$route.path,
        query: { ...this.$route.query, redirect: "true" },
      });
      await firebase
        .auth()
        .signInWithRedirect(new firebase.auth.GoogleAuthProvider());
      // await Auth.doLogin({ idToken: await (signinresult.user as any).getIdToken() })
      // await Locations.fetchAll();
    } catch (err) {
      this.loginError = err.message;
    }
    this.isLoading = false;
  }

  async doLoginEmail() {
    if (!(this.$refs.login as any).validate()) {
      return;
    }

    this.isLoadingLogin = true;
    this.loginEmailError = "";
    try {
      await Auth.doLoginEmail({ email: this.email, password: this.password });
    } catch (err) {
      this.loginEmailError = err.message;
    }
    this.isLoadingLogin = false;
  }

  async doRegister() {
    if (!(this.$refs.register as any).validate()) {
      return;
    }

    this.isLoadingRegister = true;
    this.registerError = "";
    try {
      await Auth.doRegister({
        email: this.email,
        name: this.name,
        password: this.password
      });
      this.view = 'loginEmail';
      this.doLoginEmail();
    } catch (err) {
      this.registerError = (err.response && err.response.data && err.response.data.message) || err.message;
    }
    this.isLoadingRegister = false;
  }

  async mounted() {
    this.isLoading = true;
    let result: any;

    try {
      result = await firebase.auth().getRedirectResult();
      if (!result || !result.user) {
        throw Error("");
      }
    } catch (err) {
      this.loginError = err.message;
      this.isLoading = false;
      this.$router.push({
        path: this.$route.path,
        query: { ...omit(["redirect"], this.$route.query) },
      });
      return;
    }

    try {
      this.loginError = "";
      await Auth.doLogin({ idToken: await result.user.getIdToken() });
      await Locations.fetchAll();
    } catch (err) {
      this.loginError = err.message;
      this.$router.push({
        path: this.$route.path,
        query: { ...this.$route.query, redirect: "false" },
      });
    }
    this.isLoading = false;
  }
}
