import { getModule } from 'vuex-module-decorators';

import store from './store';

import app from './app';
import auth from './auth';
import locations from './locations';

export const App = getModule(app, store);
export const Auth = getModule(auth, store);
export const Locations = getModule(locations, store);
