import axios from 'axios';
import Vue from 'vue';
import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { loadStripe } from '@stripe/stripe-js';

const STRIPE_KEY = process.env.VUE_APP_STRIPE_KEY;
const API_URL = process.env.VUE_APP_API_URL || 'http://localhost:3000';

export enum SubscriptionType {
  'INDIVIDUAL' = 'INDIVIDUAL', 'FAMILY' = 'FAMILY', 'SINGLE_USE' = 'SINGLE_USE', 'COMPANY' = 'COMPANY'
}

export interface Plan {
  slug: string;
  name: string;
  desc?: string;
  active: boolean;
  type: 'one-time' | 'recurring';
  fees?: {
    monthly?: number;
    deposit?: number;
    dailyLower?: number;
    minCharge?: number;
  };
  maxContainers: number;
  maxAccessories: number;
}

export interface Subscription {
  transactionId: string;
  subscriberId?: string;
  companyId?: string;
  plan: Plan;
  type: SubscriptionType;
  issuedAt?: Date;
  usageCount: number;
  active: boolean;
  refundedAt?: Date;
  refundedAmount?: number;
  expiresAt?: Date;
}

export type User = {
  _id: string;
  uid: string;
  name?: string;
  email?: string;
  subscriptionType?: string;
  activeReusables: number;
  status: string;
  company?: any;
  picture?: string;
  signUpCity?: string;
  phone?: string;
  lastLogin?: Date;
  subscriptions: Subscription[];
  stripeAccountId?: string;
  paymentsEnabled?: boolean;
}

@Module({ namespaced: true, name: 'auth', preserveState: true })
class Auth extends VuexModule {
  user: User | {} = {};
  error = '';
  token = '';
  offline = false;
  loading = false;

  @Mutation
  setUser(payload: User) {
    this.user = Object.assign({}, payload);
  }

  @Mutation
  removeUser() {
    this.user = {};
    this.token = '';
  }

  @Mutation
  setError(payload: string) {
    this.error = payload;
  }

  @Mutation
  setToken(payload: string) {
    this.token = payload;
  }

  @Mutation
  removeToken() {
    this.token = '';
  }

  @Mutation
  setOffline(payload: boolean) {
    this.offline = payload;
  }

  @Mutation
  setLoading(payload: boolean) {
    this.loading = payload;
  }

  @Mutation
  resetState() {
    this.user = {};
    this.error = '';
    this.token = '';
    this.offline = false;
  }

  get loggedIn() {
    return !!this.token;
  }

  @Action({ rawError: true })
  async verifyInviteCode(data: { code: string }) {
    const response = await Vue.$axios.get(`/api/verify-code/${data.code}`);
    const responseData = response.data;

    return responseData;
  }

  @Action({ rawError: true })
  async doLogin(data: { idToken: string, code?: string }) {
    const response = await axios.post(`${API_URL}/auth/login-firebase`, data);
    const responseData = response.data;

    if (!responseData) {
      throw new Error('Server error');
    }

    this.setUser(responseData.user);
    this.setToken(responseData.token);
    if (responseData.user.signUpCity) {
      document.location.href = '/';
    } else {
      document.location.href = '/setup-account';
    }
  }

  @Action({ rawError: true })
  async doLoginEmail(data: { email: string, password:string }) {
    const response = await Vue.$axios.post(`${API_URL}/auth/login-email`, data);
    const responseData = response.data;

    if (!responseData) {
      throw new Error('Server error');
    }

    this.setUser(responseData.user);
    this.setToken(responseData.token);
    if (responseData.user.signUpCity && responseData.user.phone && responseData.user.paymentsEnabled) {
      document.location.href = '/';
    } else {
      document.location.href = '/setup-account';
    }
  }

  @Action({ rawError: true })
  async doRegister(data: { email: string; name: string, password: string }) {
    const response = await Vue.$axios.post(`${API_URL}/auth/register`, data);
    const responseData = response.data;

    return responseData;
  }

  @Action({ rawError: true })
  async doLogout(data?: { redirectUrl?: string, authError?: boolean, errorCode?: string }) {
    this.removeUser();
    this.removeToken();
    this.setError('');
    if (data && (data.redirectUrl || data.authError || data.errorCode)) {
      const queryParams = new URLSearchParams();

      if (data.redirectUrl) {
        queryParams.append('redirectUrl', data.redirectUrl);
      }

      if (data.authError) {
        queryParams.append('authError', 'true');
      }

      if (data.errorCode) {
        queryParams.append('errorCode', data.errorCode);
      }

      window.location.href = `/login?${queryParams}`;
      return;
    }
    window.location.href = '/login';
    // dispatch('reset', {}, { root: true });
  }

  @Action({ rawError: true })
  async update(data: { fields: Partial<User> }) {
    const userId = (this.user as User)._id;
    if (!userId) {
      throw new Error('Not logged in');
    }
    const response = await Vue.$axios.put('/account/update', {
      ...data.fields,
    });

    const responseData = response.data;

    if (!responseData) {
      throw new Error('Server error');
    }

    this.setUser({
      ...responseData,
    });
  }

  @Action({ rawError: true })
  async updateDetails(data: { signUpCity: string, phone: string }) {
    const response = await Vue.$axios.post('/api/update-details', data);

    const responseData = response.data;

    if (!responseData) {
      throw new Error('Server error');
    }

    this.setUser({
      ...responseData,
    });
  }

  @Action({ rawError: true })
  async setupStripe() {
    const response = await Vue.$axios.post('/api/setup-stripe');

    const responseData = response.data;
    window.location.href = responseData.url;
  }

  @Action({ rawError: true })
  async finalizeStripeSetup(data: { sessionId: string }) {
    const response = await Vue.$axios.post(`/api/finalize-stripe?sessionId=${data.sessionId}`);

    const responseData = response.data;
    return responseData;
  }

  @Action({ rawError: true })
  async buySubscription(data: { planSlug: string, redirectUrl: string }) {
    const response = await Vue.$axios.post('/api/buy-subscription', {
      plan: data.planSlug,
      redirectUrl: data.redirectUrl,
    });

    const responseData = response.data;
    const stripe = await loadStripe(STRIPE_KEY as string);

    const { error } = await stripe!.redirectToCheckout({
      sessionId: responseData.sessionId
    });

    if (error) {
      throw error;
    }
  }

  @Action({ rawError: true })
  async cancelSubscription(data: { id: string }) {
    const response = await Vue.$axios.delete(`/subscriptions/${data.id}`);
    return response.data;
  }

  @Action({ rawError: true })
  async refreshAccountData() {
    const response = await Vue.$axios.get('/api/account');
    const responseData = response.data;

    this.setUser({
      ...responseData,
    });
  }

  @Action({ rawError: true })
  async takeContainers(data: { locationId: string, reusables: any[] }) {
    const response = await Vue.$axios.post('/api/take-containers', data);
    const responseData = response.data;

    return responseData;
  }

  @Action({ rawError: true })
  async returnContainers(data: { locationId: string, containers: { id: string, amount: number }[] }) {
    const response = await Vue.$axios.post('/api/return-container', data);
    const responseData = response.data;

    return responseData;
  }

  @Action({ rawError: true })
  async myContainers() {
    const response = await Vue.$axios.get('/api/my-containers');
    const responseData = response.data;

    return responseData;
  }

  @Action({ rawError: true })
  async resetStateAction() {
    this.resetState();
  }
}

export default Auth;
