import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import NotFound from '../views/404.vue';
import { Auth } from '@/store/modules';
import config from '../lib/config';
import { User } from '@/store/auth';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
    meta: {
      title: 'Home',
      authorize: true,
    }
  },
  {
    path: '/setup-account',
    name: 'setup-account',
    component: () => import(/* webpackChunkName: "setup-account" */ '../views/SetupAccount.vue'),
    meta: {
      title: 'Setup Account',
      layout: 'centered',
      authorize: true,
    }
  },
  {
    path: '/vendors',
    name: 'vendors',
    component: () => import(/* webpackChunkName: "locations" */ '../views/Locations.vue'),
    meta: {
      title: 'Restaurants',
    },
    props: {
      type: 'vendors'
    }
  },
  {
    path: '/return-stations',
    name: 'return-stations',
    component: () => import(/* webpackChunkName: "locations" */ '../views/Locations.vue'),
    meta: {
      title: 'Return Stations',
    },
    props: {
      type: 'dropSites'
    }
  },
  {
    path: '/account',
    name: 'account',
    component: () => import(/* webpackChunkName: "account" */ '../views/Account.vue'),
    meta: {
      title: 'My Account',
      authorize: true,
    },
  },
  {
    path: '/payment-success',
    name: 'payment-success',
    component: () => import(/* webpackChunkName: "payment-success" */ '../views/PaymentSuccess.vue'),
    meta: {
      title: 'Payment Successful',
      authorize: true,
    },
  },
  {
    path: '/payment-failed',
    name: 'payment-failed',
    component: () => import(/* webpackChunkName: "payment-failed" */ '../views/PaymentFailed.vue'),
    meta: {
      title: 'Payment Failed',
      authorize: true,
    },
  },
  {
    path: '/take-container',
    name: 'take-container',
    component: () => import(/* webpackChunkName: "take-container" */ '../views/TakeContainer.vue'),
    meta: {
      title: 'Check out reusables',
      authorize: true,
    },
  },
  {
    path: '/return-container',
    name: 'return-container',
    component: () => import(/* webpackChunkName: "return-container" */ '../views/ReturnContainer.vue'),
    meta: {
      title: 'Return a reusable',
      authorize: true,
    },
  },
  {
    path: '/help',
    name: 'help',
    component: () => import(/* webpackChunkName: "help" */ '../views/Help.vue'),
    meta: {
      title: "How it Works & FAQ's",
    },
  },
  {
    path: '/my-reusables',
    name: 'my-containers',
    component: () => import(/* webpackChunkName: "my-containers" */ '../views/MyContainers.vue'),
    meta: {
      title: 'My Reusables',
      authorize: true,
    },
  },
  {
    path: '/subscribe',
    name: 'subscribe',
    component: () => import(/* webpackChunkName: "subscribe" */ '../views/BuySub.vue'),
    meta: {
      title: 'Sign up',
      authorize: true,
    },
  },
  {
    path: '/scan',
    name: 'scan',
    component: () => import(/* webpackChunkName: "scan" */ '../views/ReturnScan.vue'),
    meta: {
      title: 'Scan Return Station Code',
      authorize: true,
    },
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      layout: 'centered'
    }
  },
  {
    path: '/terms-of-use',
    name: 'terms-of-use',
    component: () => import(/* webpackChunkName: "terms-of-use" */ '../views/Terms.vue'),
    meta: {
      title: 'Terms and Conditions'
      // layout: 'centered',
    }
  },
  {
    path: '/accept-invite',
    name: 'accept-invite',
    component: () => import(/* webpackChunkName: "accept-invite" */ '../views/AcceptInvite.vue'),
    meta: {
      layout: 'centered'
    }
  },
  {
    path: '/return-or-take',
    name: 'return-or-take',
    component: () => import(/* webpackChunkName: "return-or-take" */ '../views/MapOrQR.vue'),
    meta: {
      authorize: true
    }
  },
  {
    path: '*',
    name: '404',
    component: NotFound,
    meta: {
      layout: 'centered'
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  }
});

router.beforeEach((to, _from, next) => {
  const authRequired = to.meta && to.meta.authorize;
  const loggedIn = Auth.loggedIn;
  const user = Auth.user as User;

  if (authRequired && !loggedIn) {
    return next({ path: '/login', query: { redirectUrl: to.fullPath } });
  }

  if (to.name === 'login' && loggedIn) {
    return next('/');
  }

  if (loggedIn && !user?.paymentsEnabled && to.name !== 'setup-account') {
    return next('/setup-account')
  }

  const title = (to.meta && to.meta.title);
  document.title = title ? `${title} | ${config.appName}` : config.appName;

  next();
});

export default router
