import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'

@Module({ namespaced: true, name: 'app', preserveState: true })
class App extends VuexModule {
  public snackbarMessage = '';
  public snackbarDelay = 5000;
  public snackbar = false;
  public dark = false;

  @Mutation
  setSnackbarMessage(payload: string) {
    this.snackbarMessage = payload;
  };

  @Mutation
  setSnackbarDelay(payload: number) {
    this.snackbarDelay = payload;
  };

  @Mutation
  setSnackbar(payload: boolean) {
    this.snackbar = payload;
  };

  @Mutation
  setDark(payload: boolean) {
    this.dark = payload;
  };

  @Action
  showSnackbar(payload: { message: string; delay?: number }) {
    this.setSnackbar(false);
    setTimeout(() => {
      this.setSnackbarMessage(payload.message);
      this.setSnackbarDelay(payload.delay || 5000);
      this.setSnackbar(true);
    }, 200);
  };

  @Action
  hideSnackbar() {
    this.setSnackbar(false);
  };

  @Action
  switchDark() {
    this.setDark(!this.dark);
  };
}

export default App;
