











































































import Vue from "vue";
import Component from "vue-class-component";
import { User } from "@/store/auth";
import { Auth } from "@/store/modules";
import config from '../lib/config';

@Component({ name: "Layout" })
export default class extends Vue {
  drawer: boolean = false;
  menu: any[] = [
    { text: "Home", icon: "mdi-home", to: "/" },
    { text: "Restaurants", icon: "mdi-map-marker-radius", to: "/vendors" },
    {
      text: "Return Stations",
      icon: "mdi-map-marker-down",
      to: "/return-stations",
    },
    { text: "My Account", icon: "mdi-account-cog", to: "/account" },
    { text: "How it Works & FAQ's", icon: "mdi-help-box", to: "/help" },
    { text: "Terms of Use", icon: "mdi-file-document", href: "/terms-of-use" },
    { text: "Contact us", icon: "mdi-email", href: "/help?to=contact" },
  ];

  doLogout() {
    Auth.doLogout();
  }

  get loggedIn() {
    return Auth.loggedIn;
  }

  get name() {
    return (Auth.user as User).name;
  }

  get email() {
    return (Auth.user as User).email;
  }

  get picture() {
    return (Auth.user as User).picture;
  }

  get title() {
    return (this.$route.meta && this.$route.meta.title) || config.appName;
  }
}
