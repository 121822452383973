
















import Vue from "vue";
import Component from "vue-class-component";

import Default from "@/layouts/Default.vue";
import centered from "@/layouts/Centered.vue";

@Component
export default class extends Vue {
  layouts: any = {
    Default,
    centered,
  };
  refreshing = false;
  serviceWorkerRegistartion = null as any;
  updateExists = false;
  updateFound = false;

  created() {
    document.addEventListener("swUpdated", this.updateAvailable, {
      once: true,
    });
    document.addEventListener("swUpdateFound", this.onUpdateFound, {
      once: true,
    });

    navigator.serviceWorker.addEventListener("controllerchange", () => {
      if (this.refreshing) {
        return;
      }

      this.refreshing = true;
      window.location.reload();
    });
  }

  onUpdateFound() {
    this.updateFound = true;
  }

  updateAvailable(event: any) {
    this.serviceWorkerRegistartion = event.detail;
    this.updateExists = true;
  }

  refreshApp() {
    this.updateExists = false;
    this.updateFound = false;

    if (
      !this.serviceWorkerRegistartion ||
      !this.serviceWorkerRegistartion.waiting
    ) {
      return;
    }

    this.serviceWorkerRegistartion.waiting.postMessage({
      type: "SKIP_WAITING",
    });
  }

  get layout() {
    return this.$route.meta && this.$route.meta.layout;
  }

  get layoutComponent() {
    if (this.layout) {
      return this.layouts[this.layout];
    }

    return this.layouts.Default;
  }
}
