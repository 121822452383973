import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store/store'
import vuetify from './plugins/vuetify';
import GSignInButton from 'vue-google-signin-button';
import * as firebase from "firebase";
import * as VueGoogleMaps from 'vue2-google-maps'
import VueConfetti from 'vue-confetti'
import VueCarousel from 'vue-carousel';
import i18n from './i18n';
// @ts-ignore
import images from '../../config/images.json';
// @ts-ignore
import links from '../../config/links.json';
// @ts-ignore
import firebaseConfig from '../../config/firebase.json';

Vue.use(VueCarousel);

Vue.use(VueConfetti);

Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GMAPS_KEY,
    libraries: 'places',
  },
});

Vue.use(GSignInButton);

firebase.initializeApp(firebaseConfig);

Vue.config.productionTip = false

Vue.filter("formatDate", (date: any) => {
  if (!date) {
    return "Wrong date";
  }

  const dateObj = new Date(date);
  return dateObj.toLocaleDateString();
});

Vue.filter("formatDateTime", (date: any) => {
  if (!date) {
    return "Wrong date";
  }

  const dateObj = new Date(date);
  return dateObj.toLocaleString();
});

Vue.filter("formatMinutes", (minutes?: any) => {
  if (!minutes) {
    return "0:00";
  }

  const m = Number(minutes);
  const hrs = Math.floor(m / 60);
  const mins = m - hrs * 60;
  const minsStr = mins < 10 ? `0${mins}` : `${mins}`;

  return `${hrs}:${minsStr}`;
});

Vue.filter("formatSubscriptionType", (type: string) => {
  if (type === 'SINGLE_USE') {
    return 'One time use';
  }

  if (type === 'INDIVIDUAL') {
    return 'Individual';
  }

  if (type === 'FAMILY') {
    return 'Family'
  }

  if (type === 'COMPANY') {
    return 'Company'
  }
});

Vue.filter("formatLogType", (type: string) => {
  if (type === 'TAKE') {
    return 'Take';
  }

  if (type === 'RETURN') {
    return 'Return';
  }

  if (type === 'Other') {
    return 'Other Action'
  }
});

Vue.prototype.$images = images;
Vue.prototype.$links = links;

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')
