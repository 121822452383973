






import Vue from "vue";
import Component from "vue-class-component";

@Component({ name: "Layout" })
export default class extends Vue {}
