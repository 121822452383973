import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist';

import app from './app';
import auth from './auth';
import locations from './locations';

const modules = {
  app,
  auth,
  locations
};

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
});

export default new Vuex.Store({
  state: {},
  modules,
  plugins: [vuexLocal.plugin],
});
