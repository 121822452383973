<template lang="pug">
  div
    h1 Terms and Conditions
    p Last updated: May 06, 2021
    p Please read these terms and conditions carefully before using Our Service.
    h3 Interpretation and Definitions
    v-expansion-panels.my-3(accordion dense)
      v-expansion-panel
        v-expansion-panel-header
          strong Interpretation
        v-expansion-panel-content
          p The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.
      v-expansion-panel
        v-expansion-panel-header
          strong Definitions
        v-expansion-panel-content
          p For the purposes of these Terms and Conditions:
          ul
            li
              p
                strong Application
                |  means the software program provided by the Company downloaded by You on any electronic device, named Sparkl Reusables
            li
              p
                strong Application Store
                |  means the digital distribution service operated and developed by Apple Inc. (Apple App Store) or Google Inc. (Google Play Store) in which the Application has been downloaded.
            li
              p
                strong Affiliate
                |  means an entity that controls, is controlled by or is under common control with a party, where &quot;control&quot; means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority.
            li
              p
                strong Account
                |  means a unique account created for You to access our Service or parts of our Service.
            li
              p
                strong Country
                |  refers to: Hawaii,  United States
            li
              p
                strong Company
                |  (referred to as either &quot;the Company&quot;, &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot; in this Agreement) refers to Sparkl Sustainable Solutions, 621 Taylor Ave, Alameda, CA 94501.
            li
              p
                strong Device
                |  means any device that can access the Service such as a computer, a cellphone or a digital tablet.
            li
              p
                strong Service
                |  refers to the Application.
            li
              p
                strong Terms and Conditions
                |  (also referred as &quot;Terms&quot;) mean these Terms and Conditions that form the entire agreement between You and the Company regarding the use of the Service.
            li
              p
                strong Third-party Social Media Service
                |  means any services or content (including data, information, products or services) provided by a third-party that may be displayed, included or made available by the Service.
            li
              p
                strong You
                |  means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.    
    h3 Acknowledgment
    p
      | These are the Terms and Conditions governing the use of this Service and the agreement that operates between You and the Company. These Terms and Conditions set out the rights and obligations of all users regarding the use of the Service.
    p
      | Your access to and use of the Service is conditioned on Your acceptance of and compliance with these Terms and Conditions. These Terms and Conditions apply to all visitors, users and others who access or use the Service.
    p
      | By accessing or using the Service You agree to be bound by these Terms and Conditions. If You disagree with any part of these Terms and Conditions then You may not access the Service.
    p
      | You represent that you are over the age of 18. The Company does not permit those under 18 to use the Service.
    p
      | Your access to and use of the Service is also conditioned on Your acceptance of and compliance with the Privacy Policy of the Company. Our Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your personal information when You use the Application or the Website and tells You about Your privacy rights and how the law protects You. Please read Our Privacy Policy carefully before using Our Service.
    h3 Using Our Service
    v-expansion-panels.my-3(accordion)
      v-expansion-panel
        v-expansion-panel-header
          strong Deposit and Returns
        v-expansion-panel-content
          p The Service or some parts of the Service are available only after registering your user and credit card details through Sparkl Reusables’s secure third-party platform. Storing your payment information allows Sparkl Reusables to retain the deposit price of $6.50 per container if checked out containers are not returned within the rental period.
      v-expansion-panel
        v-expansion-panel-header
          strong Cancelling Your Account
        v-expansion-panel-content
          p If you wish to cancel your account, you may unregister at any time through your My Account page or by contacting us at info@sparklreusables.com. Cancelling your account will also remove your stored credit card information from our secure third-party site. As long as you have no unreturned containers, your account will be cancelled with no charges to your credit card. For each unreturned container, $6.50 will be charged to your credit card on file.
      v-expansion-panel
        v-expansion-panel-header
          strong Billing
        v-expansion-panel-content
          p
            | Please provide Sparkl Reusables with accurate and complete billing information as prompted. Incorrect payment information will prevent your use of our program.
      v-expansion-panel
        v-expansion-panel-header
          strong Fee Changes
        v-expansion-panel-content
          p Sparkl Reusables may modify deposit and fee structure to include additional non-refundable fees. During the pilot phase of the program, there are no non-refundable fees for use of the Service. You will only be charged for containers not returned after 7 days and the cost of processing the deposit payment on our third-party platform. We will provide you with reasonable prior notice of any change in deposit fees and non- refundable fees. You may terminate your account before such change and the associated additional charges become effective. Your continued use of the Service after the deposit or fee changes come into effect constitutes your agreement to pay the modified deposit or fee amount.
          p The Company will provide You with reasonable prior notice of any change in deposits or fees to give You an opportunity to terminate Your account before such changes become effective.
          p Your continued use of the Service after the deposit or fee changes come into effect constitutes Your agreement to pay the modified amounts.
      v-expansion-panel
        v-expansion-panel-header
          strong Refunds
        v-expansion-panel-content
          p
            | During our pilot phase, the use of the Sparkl Reusables Service is free for users, as long as all checked-out containers have been returned on time. If You don't return checked-out containers, we will deduct $6.50 per container using Your stored payment information. The Company charging You for the unreturned containers will allow You to once again check out containers from the Service, once the charge has cleared.
          p
            | Certain refund requests for containers returned after the check out period may be considered by the Company on a case-by-case basis and granted at the sole discretion of the Company. Frequent late returns or repeated requests for refunds may lead to cancellation of a user’s account at the sole discretion of the Company.
    h3 User Accounts
    p During our pilot phase, the use of the Sparkl Reusables Service is free for users, as long as all checked-out containers have been returned on time. If You don't return checked-out containers, we will deduct $6.50 per container using Your stored payment information. The Company charging You for the unreturned containers will allow You to once again check out containers from the Service, once the charge has cleared.
    p Certain refund requests for containers returned after the check out period may be considered by the Company on a case-by-case basis and granted at the sole discretion of the Company. Frequent late returns or repeated requests for refunds may lead to cancellation of a user’s account at the sole discretion of the Company.
    h3 Intellectual Property
    p
      | The Service and its original content (excluding Content provided by You or other users), features and functionality are and will remain the exclusive property of the Company and its licensors.
    p
      | The Service is protected by copyright, trademark, and other laws of both the Country and foreign countries.
    p
      | Our trademarks and trade dress may not be used in connection with any product or service without the prior written consent of the Company.
    h3 Links to Other Websites
    p
      | Our Service may contain links to third-party web sites or services that are not owned or controlled by the Company.
    p
      | The Company has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third party web sites or services. You further acknowledge and agree that the Company shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods or services available on or through any such web sites or services.
    p
      | We strongly advise You to read the terms and conditions and privacy policies of any third-party web sites or services that You visit.
    h3 Termination
    p
      | We may terminate or suspend Your Account immediately, without prior notice or liability, for any reason whatsoever, including without limitation if You breach these Terms and Conditions.
    p
      | Upon termination, Your right to use the Service will cease immediately. If You wish to terminate Your Account, You may simply discontinue using the Service.
    h3 Limitation of Liability
    p
      | Notwithstanding any damages that You might incur, the entire liability of the Company and any of its suppliers under any provision of this Terms and Your exclusive remedy for all of the foregoing shall be limited to the amount actually paid by You through the Service or 100 USD if You haven&apos;t purchased anything through the Service.
    p
      | To the maximum extent permitted by applicable law, in no event shall the Company or its suppliers be liable for any special, incidental, indirect, or consequential damages whatsoever (including, but not limited to, damages for loss of profits, loss of data or other information, for business interruption, for personal injury, loss of privacy arising out of or in any way related to the use of or inability to use the Service, third-party software and/or third-party hardware used with the Service, or otherwise in connection with any provision of this Terms), even if the Company or any supplier has been advised of the possibility of such damages and even if the remedy fails of its essential purpose.
    p
      | Some states do not allow the exclusion of implied warranties or limitation of liability for incidental or consequential damages, which means that some of the above limitations may not apply. In these states, each party&apos;s liability will be limited to the greatest extent permitted by law.
    h3 &quot;AS IS&quot; and &quot;AS AVAILABLE&quot; Disclaimer
    p
      | The Service is provided to You &quot;AS IS&quot; and &quot;AS AVAILABLE&quot; and with all faults and defects without warranty of any kind. To the maximum extent permitted under applicable law, the Company, on its own behalf and on behalf of its Affiliates and its and their respective licensors and service providers, expressly disclaims all warranties, whether express, implied, statutory or otherwise, with respect to the Service, including all implied warranties of merchantability, fitness for a particular purpose, title and non-infringement, and warranties that may arise out of course of dealing, course of performance, usage or trade practice. Without limitation to the foregoing, the Company provides no warranty or undertaking, and makes no representation of any kind that the Service will meet Your requirements, achieve any intended results, be compatible or work with any other software, applications, systems or services, operate without interruption, meet any performance or reliability standards or be error free or that any errors or defects can or will be corrected.
    p
      | Without limiting the foregoing, neither the Company nor any of the company&apos;s provider makes any representation or warranty of any kind, express or implied: (i) as to the operation or availability of the Service, or the information, content, and materials or products included thereon; (ii) that the Service will be uninterrupted or error-free; (iii) as to the accuracy, reliability, or currency of any information or content provided through the Service; or (iv) that the Service, its servers, the content, or e-mails sent from or on behalf of the Company are free of viruses, scripts, trojan horses, worms, malware, timebombs or other harmful components.
    p
      | Some jurisdictions do not allow the exclusion of certain types of warranties or limitations on applicable statutory rights of a consumer, so some or all of the above exclusions and limitations may not apply to You. But in such a case the exclusions and limitations set forth in this section shall be applied to the greatest extent enforceable under applicable law.
    h3 Governing Law
    p
      | The laws of the Country, excluding its conflicts of law rules, shall govern this Terms and Your use of the Service. Your use of the Application may also be subject to other local, state, national, or international laws.
    h3 Disputes Resolution
    p
      | If You have any concern or dispute about the Service, You agree to first try to resolve the dispute informally by contacting the Company.
    h3 For European Union (EU) Users
    p
      | If You are a European Union consumer, you will benefit from any mandatory provisions of the law of the country in which you are resident in.
    h3 United States Federal Government End Use Provisions
    p
      | If You are a U.S. federal government end user, our Service is a &quot;Commercial Item&quot; as that term is defined at 48 C.F.R. &sect;2.101.
    h3 United States Legal Compliance
    p
      | You represent and warrant that (i) You are not located in a country that is subject to the United States government embargo, or that has been designated by the United States government as a &quot;terrorist supporting &quot; country, and (ii) You are not listed on any United States government list of prohibited or restricted parties.
    h3 Severability and Waiver
    v-expansion-panels.my-3(accordion)
      v-expansion-panel
        v-expansion-panel-header
          strong Severability
        v-expansion-panel-content
          p
            | If any provision of these Terms is held to be unenforceable or invalid, such provision will be changed and interpreted to accomplish the objectives of such provision to the greatest extent possible under applicable law and the remaining provisions will continue in full force and effect.
      v-expansion-panel
        v-expansion-panel-header
          strong Waiver
        v-expansion-panel-content
          p
            | Except as provided herein, the failure to exercise a right or to require performance of an obligation under these Terms shall not affect a party&apos;s ability to exercise such right or require such performance at any time thereafter nor shall the waiver of a breach constitute a waiver of any subsequent breach.
    h3 Translation Interpretation
    p
      | These Terms and Conditions may have been translated if We have made them available to You on our Service.
      | You agree that the original English text shall prevail in the case of a dispute.
    h3 Changes to These Terms and Conditions
    p
      | We reserve the right, at Our sole discretion, to modify or replace these Terms at any time. If a revision is material We will make reasonable efforts to provide at least 30 days&apos; notice prior to any new terms taking effect. What constitutes a material change will be determined at Our sole discretion.
    p
      | By continuing to access or use Our Service after those revisions become effective, You agree to be bound by the revised terms. If You do not agree to the new terms, in whole or in part, please stop using the website and the Service.
    h3 Contact Us
    p If you have any questions about these Terms and Conditions, You can contact us:
    ul
      li
        p By email: info@sparklreusables.com
      li
        p
          | By visiting this page on our website: 
          a(href='https://sparklreusables.com/' rel='external nofollow noopener' target='_blank') https://sparklreusables.com/
    h3 End-User License Agreement (&quot;Agreement&quot;)
    p Last updated: March 08, 2021
    p
      | Please read this End-User License Agreement carefully before clicking the &quot;I Agree&quot; button, downloading or using Sparkl Reusables .
    h3 Interpretation and Definitions
    v-expansion-panels.my-3(accordion)
      v-expansion-panel
        v-expansion-panel-header
          strong Interpretation
        v-expansion-panel-content
          p
            | The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.
      v-expansion-panel
        v-expansion-panel-header
          strong Definitions
        v-expansion-panel-content
          p For the purposes of this End-User License Agreement:
          ul
            li
              p
                strong Agreement
                |  means this End-User License Agreement that forms the entire agreement between You and the Company regarding the use of the Application.
            li
              p
                strong Application
                |  means the software program provided by the Company downloaded by You to a Device, named Sparkl Reusables
            li
              p
                strong Company
                |  (referred to as either &quot;the Company&quot;, &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot; in this Agreement) refers to Sparkl Reusables, PO Box 62001, Honolulu, HI96822.
            li
              p
                strong Content
                |  refers to content such as text, images, or other information that can be posted, uploaded, linked to or otherwise made available by You, regardless of the form of that content.
            li
              p
                strong Country
                |  refers to: Hawaii,  United States
            li
              p
                strong Device
                |  means any device that can access the Application such as a computer, a cellphone or a digital tablet.
            li
              p
                strong Third-Party Services
                |  means any services or content (including data, information, applications and other products services) provided by a third-party that may be displayed, included or made available by the Application.
            li
              p
                strong You
                |  means the individual accessing or using the Application or the company, or other legal entity on behalf of which such individual is accessing or using the Application, as applicable.
    h3 Acknowledgment
    p
      | By clicking the &quot;I Agree&quot; button, downloading or using the Application, You are agreeing to be bound by the terms and conditions of this Agreement. If You do not agree to the terms of this Agreement, do not click on the &quot;I Agree&quot; button, do not download or do not use the Application.
    p
      | This Agreement is a legal document between You and the Company and it governs your use of the Application made available to You by the Company.
    p
      | The Application is licensed, not sold, to You by the Company for use strictly in accordance with the terms of this Agreement.
    h3 License
    v-expansion-panels.my-3(accordion)
      v-expansion-panel
        v-expansion-panel-header
          strong Scope of License
        v-expansion-panel-content
          p
            | The Company grants You a revocable, non-exclusive, non-transferable, limited license to download, install and use the Application strictly in accordance with the terms of this Agreement.
          p
            | The license that is granted to You by the Company is solely for your personal, non-commercial purposes strictly in accordance with the terms of this Agreement.
      v-expansion-panel
        v-expansion-panel-header
          strong License Restrictions
        v-expansion-panel-content
          p You agree not to, and You will not permit others to:
          ul
            li
              | License, sell, rent, lease, assign, distribute, transmit, host, outsource, disclose or otherwise commercially exploit the Application or make the Application available to any third party.
            li
              | Copy or use the Application for any purpose other than as permitted under the above section &apos;License&apos;.
            li
              | Modify, make derivative works of, disassemble, decrypt, reverse compile or reverse engineer any part of the Application.
            li
              | Remove, alter or obscure any proprietary notice (including any notice of copyright or trademark) of the Company or its affiliates, partners, suppliers or the licensors of the Application.
    h3 Third-Party Services
    p
      | The Application may display, include or make available third-party content (including data, information, applications and other products services) or provide links to third-party websites or services.
    p
      | You acknowledge and agree that the Company shall not be responsible for any Third-party Services, including their accuracy, completeness, timeliness, validity, copyright compliance, legality, decency, quality or any other aspect thereof. The Company does not assume and shall not have any liability or responsibility to You or any other person or entity for any Third-party Services.
    p
      | You must comply with applicable Third parties&apos; Terms of agreement when using the Application. Third-party Services and links thereto are provided solely as a convenience to You and You access and use them entirely at your own risk and subject to such third parties&apos; Terms and conditions.
    h3 Term and Termination
    p
      | This Agreement shall remain in effect until terminated by You or the Company.
      | The Company may, in its sole discretion, at any time and for any or no reason, suspend or terminate this Agreement with or without prior notice.
    p
      | This Agreement will terminate immediately, without prior notice from the Company, in the event that you fail to comply with any provision of this Agreement. You may also terminate this Agreement by deleting the Application and all copies thereof from your Device or from your computer.
    p
      | Upon termination of this Agreement, You shall cease all use of the Application and delete all copies of the Application from your Device.
    p
      | Termination of this Agreement will not limit any of the Company&apos;s rights or remedies at law or in equity in case of breach by You (during the term of this Agreement) of any of your obligations under the present Agreement.
    h3 Indemnification
    p
      | You agree to indemnify and hold the Company and its parents, subsidiaries, affiliates, officers, employees, agents, partners and licensors (if any) harmless from any claim or demand, including reasonable attorneys&apos; fees, due to or arising out of your: (a) use of the Application; (b) violation of this Agreement or any law or regulation; or (c) violation of any right of a third party.
    h3 No Warranties
    p
      | The Application is provided to You &quot;AS IS&quot; and &quot;AS AVAILABLE&quot; and with all faults and defects without warranty of any kind. To the maximum extent permitted under applicable law, the Company, on its own behalf and on behalf of its affiliates and its and their respective licensors and service providers, expressly disclaims all warranties, whether express, implied, statutory or otherwise, with respect to the Application, including all implied warranties of merchantability, fitness for a particular purpose, title and non-infringement, and warranties that may arise out of course of dealing, course of performance, usage or trade practice. Without limitation to the foregoing, the Company provides no warranty or undertaking, and makes no representation of any kind that the Application will meet your requirements, achieve any intended results, be compatible or work with any other software, applications, systems or services, operate without interruption, meet any performance or reliability standards or be error free or that any errors or defects can or will be corrected.
    p
      | Without limiting the foregoing, neither the Company nor any of the company&apos;s provider makes any representation or warranty of any kind, express or implied: (i) as to the operation or availability of the Application, or the information, content, and materials or products included thereon; (ii) that the Application will be uninterrupted or error-free; (iii) as to the accuracy, reliability, or currency of any information or content provided through the Application; or (iv) that the Application, its servers, the content, or e-mails sent from or on behalf of the Company are free of viruses, scripts, trojan horses, worms, malware, timebombs or other harmful components.
    p
      | Some jurisdictions do not allow the exclusion of certain types of warranties or limitations on applicable statutory rights of a consumer, so some or all of the above exclusions and limitations may not apply to You. But in such a case the exclusions and limitations set forth in this section 11 shall be applied to the greatest extent enforceable under applicable law. To the extent any warranty exists under law that cannot be disclaimed, the Company shall be solely responsible for such warranty.
    h3 Limitation of Liability
    p
      | Notwithstanding any damages that You might incur, the entire liability of the Company and any of its suppliers under any provision of this Agreement and your exclusive remedy for all of the foregoing shall be limited to the amount actually paid by You for the Application or through the Application.
    p
      | To the maximum extent permitted by applicable law, in no event shall the Company or its suppliers be liable for any special, incidental, indirect, or consequential damages whatsoever (including, but not limited to, damages for loss of profits, loss of data or other information, for business interruption, for personal injury, loss of privacy arising out of or in any way related to the use of or inability to use the Application, third-party software and/or third-party hardware used with the Application, or otherwise in connection with any provision of this Agreement), even if the Company or any supplier has been advised of the possibility of such damages and even if the remedy fails of its essential purpose.
    p
      | Some states/jurisdictions do not allow the exclusion or limitation of incidental or consequential damages, so the above limitation or exclusion may not apply to You.
    h3 Severability and Waiver
    v-expansion-panels.my-3(accordion)
      v-expansion-panel
        v-expansion-panel-header
          strong Severability
        v-expansion-panel-content
          p
            | If any provision of this Agreement is held to be unenforceable or invalid, such provision will be changed and interpreted to accomplish the objectives of such provision to the greatest extent possible under applicable law and the remaining provisions will continue in full force and effect.
      v-expansion-panel
        v-expansion-panel-header
          strong Waiver
        v-expansion-panel-content
          p
            | Except as provided herein, the failure to exercise a right or to require performance of an obligation under this Agreement shall not effect a party&apos;s ability to exercise such right or require such performance at any time thereafter nor shall be the waiver of a breach constitute a waiver of any subsequent breach.
    h3 Product Claims
    p The Company does not make any warranties concerning the Application.
    h3 United States Legal Compliance
    p
      | You represent and warrant that (i) You are not located in a country that is subject to the United States government embargo, or that has been designated by the United States government as a &quot;terrorist supporting&quot; country, and (ii) You are not listed on any United States government list of prohibited or restricted parties.
    h3 Changes to this Agreement
    p
      | The Company reserves the right, at its sole discretion, to modify or replace this Agreement at any time. If a revision is material we will provide at least 30 days&apos; notice prior to any new terms taking effect. What constitutes a material change will be determined at the sole discretion of the Company.
    p
      | By continuing to access or use the Application after any revisions become effective, You agree to be bound by the revised terms. If You do not agree to the new terms, You are no longer authorized to use the Application.
    h3 Governing Law
    p
      | The laws of the Country, excluding its conflicts of law rules, shall govern this Agreement and your use of the Application. Your use of the Application may also be subject to other local, state, national, or international laws.
    h3 Entire Agreement
    p
      | The Agreement constitutes the entire agreement between You and the Company regarding your use of the Application and supersedes all prior and contemporaneous written or oral agreements between You and the Company.
    p
      | You may be subject to additional terms and conditions that apply when You use or purchase other Company&apos;s services, which the Company will provide to You at the time of such use or purchase.
    h3 Contact Us
    p If you have any questions about this Agreement, You can contact Us:
    ul
      li By email:
        | 
        a(href="mailto:info@sparklreusables.com") info@sparklreusables.com
</template>

<script lang="js">
import Vue from "vue";
import Component from "vue-class-component";

@Component
export default class Terms extends Vue {}
</script>

<style scoped>
h3 {
  margin-bottom: 24px !important;
  margin-top: 32px !important;
}

h3 {
  margin-bottom: 12px !important;
  margin-top: 24px !important;
}

p {
  text-justify: inter-word !important;
  width: 100%;
}
</style>