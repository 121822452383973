import Vue from 'vue';
import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import axios from 'axios';
import defaultLocations from '../../../config/map.json'

export type Location = {
  name: string;
  slug: string;
  isVendor: boolean;
  isReturnStation: boolean;
  containers: Container[];
  city?: string;
  streetAddress?: string;
  zipCode?: string;
  state?: string;
  lat?: number;
  lng?: number;
}

export interface Container {
  id: string;
  availableCount: number;
}

export type CreateLocationPayload = {
  slug: string;
  name: string;
  type: string;
  containers: [];
  city: string;
  streetAddress: string;
  zipCode: string;
  state: string;
  contacts: [],
  lat: number;
  lng: number;
};

export type UpdateLocationPayload = Partial<CreateLocationPayload>;

export type ContainerType = {
  id: string;
  name: string;
  size: string;
  isContainer?: boolean;
}

@Module({ namespaced: true, name: 'locations', preserveState: true })
class Locations extends VuexModule {
  vendors: Location[] = [];
  dropSites: Location[] = [];
  error = '';
  loading = false;
  containerTypes: ContainerType[] = [];
  defaultLocation = {
    lat: '',
    lon: '',
    zoom: ''
  };

  @Mutation
  setVendors(payload: Location[]) {
    this.vendors = payload;
  }

  @Mutation
  setDropSites(payload: Location[]) {
    this.dropSites = payload;
  }

  @Mutation
  setContainerTypes(payload: ContainerType[]) {
    this.containerTypes = payload;
  }

  @Mutation
  setError(payload: string) {
    this.error = payload;
  }

  @Mutation
  setLoading(payload: boolean) {
    this.loading = payload;
  }

  @Mutation
  resetState() {
    this.vendors = [];
    this.dropSites = [];
    this.error = '';
    this.loading = false;
    this.containerTypes = [];
  }

  @Mutation
  setDefaultLocation(payload: any) {
    this.defaultLocation = payload;
  }

  @Action
  fetchDefault() {
    const response = defaultLocations.default;
    this.setDefaultLocation(response);
  }

  @Action
  async fetchAll() {
    this.fetchDefault();
    this.setLoading(true);
    this.setError('');
    try {
      const response = await Vue.$axios.get('/api/locations');
      const vendors = response.data.filter((location: Location) => location.isVendor);
      const dropSites = response.data.filter((location: Location) => location.isReturnStation);
      this.setVendors(vendors);
      this.setDropSites(dropSites);
      try {
        const containersResponse = await Vue.$axios.get('/api/container-types');
        this.setContainerTypes(containersResponse.data);
      } catch (err) { }
    } catch (err) {
      this.setError(err.message || "Cannot fetch locations");
    }
    this.setLoading(false);
  }

  @Action({ rawError: true })
  async fetchOne(data: { slug: string }) {
    const response = await Vue.$axios.get(`/api/locations/${data.slug}`);
    const responseData = response.data;

    return responseData;
  }

  @Action({ rawError: true })
  async geocodeAddress(data: { address: string }) {
    const encodedAddress = encodeURI(data.address);
    const response = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${encodedAddress}&key=${process.env.VUE_APP_GMAPS_KEY}`);
    return response.data;
  }

  @Action
  async resetStateAction() {
    this.resetState();
  }
}

export default Locations;
